<template>
  <v-container v-if="hasPermission" fluid>
    <mex-sperm-spinner v-if="failedAuthenticationLogsLoading" spinnerText="Loading FailedAuthenticationLogs" />
    <template v-else>
      <v-row justify="center">
        <v-col cols="12">
          <mex-heading content="Failed Authentication Logs" />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="12">
          <v-tabs
            v-model="currentTab"
            background-color="foreground"
            color="primaryAccent"
            class="mt-5"
            grow
          >
            <v-tab>
              <v-row justify="center">
                <v-col align-self="center" cols="auto">Clinic</v-col>
                <v-col align-self="center" cols="auto">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-col>
                <v-col align-self="center" cols="auto">Logs</v-col>
              </v-row>
            </v-tab>
            <v-tab>
              <v-row justify="center">
                <v-col align-self="center" cols="auto">Error</v-col>
                <v-col align-self="center" cols="auto">
                  <v-icon>mdi-arrow-right</v-icon>
                </v-col>
                <v-col align-self="center" cols="auto">Logs</v-col>
              </v-row>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="currentTab" style="background-color: var(--v-foreground-base)">
            <v-tab-item class="pa-5">
                <mex-data-table
                  v-if="clinicsToLogsData.length"
                  :data="clinicsToLogsData"
                  :headers="clinicsToLogsHeaders"
                  :items-per-page="getTablePagination.defaultRowsPerPage"
                  :footer-props="{
                    'items-per-page-options': getTablePagination.rowsPerPage,
                  }"
                  table-class="foreground"
                  dense
                  show-expand
                  single-expand
                  item-key="clinicUUID"
                >
                  <template v-slot:item.clinicName="{ item }">
                    <v-btn v-if="!!item.clinicId" style="font-size: smaller; height: fit-content" class="pa-1" color="foreground" small @click="goToClinicView(item.clinicId)">{{ item.clinicName }}</v-btn>
                    <span v-else>{{ item.clinicName || "-"}}</span>
                  </template>
                  <template v-slot:expanded-item="{ headers, item }">
                    <td :colspan="headers.length" class="extendedClinicRow">
                      <mex-data-table
                        :data="item.failedAuthenticationLogs"
                        :headers="expandedClinicHeaders"
                        table-class="foreground"
                        dense
                        :sort-by="['lastContact']"
                        :sort-desc="[true]"
                      >
                        <template v-slot:item.lastContact="{ item }">
                          {{ $dateFormatter.convertJsonDate(item.lastContact).full}}
                        </template>
                        <template v-slot:item.actions="{ item }">
                          <v-menu offset-y>
                            <template v-slot:activator="{ attrs, on }">
                              <v-btn
                                color="primary"
                                x-small
                                class="white--text"
                                v-bind="attrs"
                                v-on="on"
                              >
                                <v-icon>mdi-triangle-small-down</v-icon>
                              </v-btn>
                            </template>

                            <v-list dense>
                              <v-list-item
                                dense
                                v-for="(cmItem, index) in cmItems"
                                :key="item.FailedAuthenticationLogID + index"
                                @click="cmItem.function(item)"
                                :disabled="!checkAction(item, cmItem)"
                              >
                                <v-list-item-icon class="mr-1">
                                  <v-icon small>{{ cmItem.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="ma-1" v-text="cmItem.title"></v-list-item-title>
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </template>
                      </mex-data-table>
                    </td>
                  </template>
                </mex-data-table>
            </v-tab-item>
            <v-tab-item class="pa-5">
              <mex-data-table
                v-if="errorToLogsData.length"
                :data="errorToLogsData"
                :headers="errorToLogsHeaders"
                :items-per-page="getTablePagination.defaultRowsPerPage"
                :footer-props="{
                    'items-per-page-options': getTablePagination.rowsPerPage,
                  }"
                table-class="foreground"
                dense
                show-expand
                single-expand
                item-key="errorMessage"
              >
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="extendedClinicRow">
                    <mex-data-table
                      :data="item.failedAuthenticationLogs"
                      :headers="getExpandedErrorHeaders(item)"
                      table-class="foreground"
                      dense
                      search="Clinic UUID"
                      :sort-by="['lastContact']"
                      :sort-desc="[true]"
                    >
                      <template v-slot:item.expiredSince="{ item }">
                        {{ item.expiredSince || "-" }}
                      </template>
                      <template v-slot:item.clinicName="{ item }">
                        <v-btn v-if="!!item.clinicId" style="font-size: smaller; height: fit-content" class="pa-1" color="foreground" small @click="goToClinicView(item.clinicId)">{{ item.clinicName }}</v-btn>
                        <span v-else>{{ item.clinicName || "-"}}</span>
                      </template>
                      <template v-slot:item.lastContact="{ item }">
                        {{ $dateFormatter.convertJsonDate(item.lastContact).full}}
                      </template>
                      <template v-slot:item.actions="{ item }">
                        <v-menu offset-y>
                          <template v-slot:activator="{ attrs, on }">
                            <v-btn
                              color="primary"
                              x-small
                              class="white--text"
                              v-bind="attrs"
                              v-on="on"
                            >
                              <v-icon>mdi-triangle-small-down</v-icon>
                            </v-btn>
                          </template>

                          <v-list dense>
                            <v-list-item
                              dense
                              v-for="(cmItem, index) in cmItems"
                              :key="item.FailedAuthenticationLogID + index"
                              @click="cmItem.function(item)"
                              :disabled="!checkAction(item, cmItem)"
                            >
                              <v-list-item-icon class="mr-1">
                                <v-icon small>{{ cmItem.icon }}</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title class="ma-1" v-text="cmItem.title"></v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </template>
                    </mex-data-table>
                  </td>
                </template>
              </mex-data-table>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>
      <add-mac-address-to-blocklist-dialog
        :showAddMacAddressToBlocklistDialog="showAddMacAddressToBlocklistDialog"
        :macAddressPreSelection="macAddressPreSelection"
        @reject="showAddMacAddressToBlocklistDialog = false"
        @confirm="showAddMacAddressToBlocklistDialog = false"
      />
      <add-clinic-uuid-to-blocklist-dialog
        :showAddClinicUUIDToBlocklistDialog="showAddClinicUUIDToBlocklistDialog"
        :clinicUUIDPreSelection="clinicUUIDPreSelection"
        @reject="showAddClinicUUIDToBlocklistDialog = false"
        @confirm="showAddClinicUUIDToBlocklistDialog = false"
      />
      <set-new-mac-address-dialog
        :showSetNewMacAddressDialog="showSetNewMacAddressDialog"
        :clinicUUIDPreSelection="clinicUUIDPreSelection"
        :macAddressPreSelection="macAddressPreSelection"
        @reject="showSetNewMacAddressDialog = false"
        @confirm="setNewMACAddress"
      />
    </template>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../../requiredPermissions";
import FailedAuthenticationLogsService from "../../services/failedAuthenticationLogs.service";
import AddMacAddressToBlocklistDialog
  from "../../components/LicSrvComponents/AddMacAddressToBlocklistDialog";
import AddClinicUuidToBlocklistDialog
  from "../../components/LicSrvComponents/AddClinicUUIDToBlocklistDialog";
import tablePagination from '../../config/tablePagination.config';
import SetNewMacAddressDialog from "../../components/LicSrvComponents/SetNewMacAddressDialog";
import MexDataTable from "../../components/MedITEX_Vue_Components/MexComponents/MexDataTable.vue";
import ChipRow from "../../components/LicSrvComponents/ChipRow.vue";
import $dateFormatter from "../../functions/dateFormatter";

export default {
  name: "FailedAuthenticationLogs",
  components: {
    ChipRow, MexDataTable,
    AddClinicUuidToBlocklistDialog,
    AddMacAddressToBlocklistDialog,
    SetNewMacAddressDialog
  },
  computed: {
    $dateFormatter() {
      return $dateFormatter
    },
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getTablePagination() {
      return tablePagination;
    }
  },
  data() {
    return {
      currentTab: 0,
      showContextMenu: false,
      cmPosX: 0,
      cmPosY: 0,
      cmItems: [
        {
          title: "Regenerate UUID's",
          icon: "mdi-reload-alert",
          function: this.triggerRegenrate
        },
        {
          title: "Deactivate immediately",
          icon: "mdi-lock-alert",
          function: this.triggerDeactivation
        },
        {
          title: "Set this MAC address",
          icon: "mdi-shield-check",
          function: this.setNewMacAddress
        },
        {
          title: "Block MAC address",
          icon: "mdi-shield-alert",
          function: this.addToMacAddressBlocklist
        },
        {
          title: "Block Clinic UUID",
          icon: "mdi-shield-alert-outline",
          function: this.addToClinicUUIDBlocklist
        },
        {
          title: "Remove entry",
          icon: "mdi-close",
          function: this.removeEntry
        }
      ],
      errorToLogsHeaders: [
        {
          text: "Error Message",
          align: "start",
          sortable: true,
          value: "errorMessage"
        },
        {
          text: "Nr. Logs",
          align: "start",
          sortable: true,
          value: "failedAuthenticationLogs.length"
        }
      ],
      clinicsToLogsHeaders: [
        {
          text: "Clinic UUID",
          align: "start",
          sortable: true,
          value: "clinicUUID",
        },
        {
          text: "Clinic Name",
          align: "start",
          sortable: true,
          value: "clinicName",
        },
        {
          text: "Nr. Logs",
          align: "start",
          sortable: true,
          value: "failedAuthenticationLogs.length"
        },
      ],
      expandedErrorHeaders: [
        {
          text: "Clinic UUID",
          align: "start",
          sortable: true,
          value: "clinicUUID",
        },
        {
          text: "Clinic Name",
          align: "start",
          sortable: true,
          value: "clinicName",
        },
        {
          text: "MAC Address",
          align: "start",
          sortable: true,
          value: "macAddress"
        },
        {
          text: "Last contact at",
          align: "start",
          sortable: true,
          value: "lastContact"
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions"
        },
      ],
      expandedClinicHeaders: [
        {
          text: "Error Message",
          align: "start",
          sortable: true,
          value: "errorMessage"
        },
        {
          text: "MAC Address",
          align: "start",
          sortable: true,
          value: "macAddress"
        },
        {
          text: "Last contact at",
          align: "start",
          sortable: true,
          value: "lastContact"
        },
        {
          text: "Actions",
          align: "start",
          sortable: false,
          value: "actions"
        }
      ],
      // data
      hasPermission: false,
      failedAuthenticationLogsLoading: false,
      showAddMacAddressToBlocklistDialog: false,
      showSetNewMacAddressDialog: false,
      macAddressPreSelection: "",
      showAddClinicUUIDToBlocklistDialog: false,
      clinicUUIDPreSelection: ""
    };
  },
  created() {
    // REPLACE WITH REAL PERMISSIONS
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.systemSettings, this.$store)
      .then((hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.fetchFailedAuthenticationLogs();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch(() => {
        this.$router.push({ name: "NotFound" });
      })
  },
  methods: {
    // turn timespans from the "Credentials expired" logs into seconds
    timespanToSeconds(timespan) {
      if (!timespan) {
        return 0;
      }
      const regex = /(\d+)m (\d+)s/;
      const match = timespan.match(regex);
      if (!match) {
        return 0;
      }
      const [, minutes, seconds] = match;
      return parseInt(minutes) * 60 + parseInt(seconds);
    },
    // get from the "Credentials expired" logs the timespan since the credentials expired
    extractExpiredSince(item) {
      if (!item) {
        return null;
      }
      const regex = /(?<=Credentials expired since\s).*/;
      const match = item.errorMessage.match(regex);
      if (!match) {
        return null;
      }
      const timespan = item.errorMessage.match(regex)[0];
      return timespan;
    },
    // if the error is "Credentials expired", add the "Expired since" header, else just return the default headers
    // from the errorToLogsHeaders
    getExpandedErrorHeaders(item) {
      let headers = [...this.expandedErrorHeaders];
      if (item.errorMessage === "Credentials expired") {
        headers.unshift({
          text: "Expired since",
          align: "start",
          sortable: true,
          value: "expiredSince",
          sort: (a, b) => this.timespanToSeconds(a) - this.timespanToSeconds(b)
        })
      }
      return headers;
    },
    setNewMACAddress() {
      this.showSetNewMacAddressDialog = false;
      this.fetchFailedAuthenticationLogs();
    },
    fetchFailedAuthenticationLogs() {
      this.failedAuthenticationLogsLoading = true;
      FailedAuthenticationLogsService.getFailedAuthenticationLogs()
        .then((response) => {
          this.failedAuthenticationLogsLoading = false;
          [this.clinicsToLogsData, this.errorToLogsData] = [response.data.clinicsToLogs, response.data.errorToLogs];
          const credentialsExpiredIdx = this.errorToLogsData.findIndex((error) => error.errorMessage === "Credentials expired");
          if (credentialsExpiredIdx !== -1) {
            this.errorToLogsData[credentialsExpiredIdx].failedAuthenticationLogs.forEach((log) => {
              log.expiredSince = this.extractExpiredSince(log);
            });
          }
          this.sanitizeClinicUUIDs();
        })
        .catch((err) => {
          this.failedAuthenticationLogsLoading = false;
          this.$toast.error(err.response.data);
        });
    },
    sanitizeClinicUUIDs() {
      const invalidClinicUUIDs = this.clinicsToLogsData.filter((clinic) => !this.isValidUUID(clinic.clinicUUID)).map(clinic => clinic.failedAuthenticationLogs);
      this.clinicsToLogsData = this.clinicsToLogsData.filter((clinic) => this.isValidUUID(clinic.clinicUUID));
      this.clinicsToLogsData.push({
        clinicUUID: "All clinics with invalid UUIDs",
        clinicName: "",
        failedAuthenticationLogs: invalidClinicUUIDs.flat(1)
      });
    },
    setContextMenu(e, item) {
      e.preventDefault();
      this.showContextMenu = false;
      this.cmPosX = e.clientX;
      this.cmPosY = e.clientY;
      this.$nextTick(() => {
        this.showContextMenu = true;
      });
      this.selectedItem = item;
    },
    triggerRegenrate() {
      this.$toast.warning("The regenration is currently not available");
    },
    triggerDeactivation() {
      this.$toast.warning("The deactivation is currently not available");
    },
    setNewMacAddress(item ) {
      this.showSetNewMacAddressDialog = true;
      this.macAddressPreSelection = item.macAddress;
      this.clinicUUIDPreSelection = item.clinicUUID;
    },
    addToMacAddressBlocklist(item) {
      this.showAddMacAddressToBlocklistDialog = true;
      this.macAddressPreSelection = item.macAddress;
    },
    addToClinicUUIDBlocklist(item) {
      this.showAddClinicUUIDToBlocklistDialog = true;
      this.clinicUUIDPreSelection = item.clinicUUID;
    },
    removeEntry(item) {
      FailedAuthenticationLogsService.deleteFailedAuthenticationLog(item.FailedAuthenticationLogID)
        .then(() => {
          this.fetchFailedAuthenticationLogs();
          this.$toast.success("Entry successfully removed from Failed Authentication Logs");
        })
        .catch((err) => {
          this.$toast.error(err.response.data);
        });
    },
    checkAction(item, cmItem) {
      if (cmItem.title === "Remove entry") {
        return true;
      }
      const titleToFlagMapper = {
        "Regenerate UUID's": "regenerateFlag",
        "Deactivate immediately": "deactivateFlag",
        "Block MAC address": "macAddressBlocklist",
        "Block Clinic UUID": "clinicUUIDBlocklist",
        "Set this MAC address": "setNewMacAddress"
      };
      return item[titleToFlagMapper[cmItem.title]];
    },
    goToClinicView(id) {
      this.$router.push({ name: 'ClinicView', params: { id } });
    },
    isValidUUID (uuid) {
      const regex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
      return regex.test(uuid);
    }
  }
};
</script>

<style>
.extendedClinicRow {
  box-shadow: inset 0 0 1em var(--v-primary-base);
}
</style>
