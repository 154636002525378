import httpClient from "../httpClient/httpClient";

const FailedAuthenticationLogsService = {
  getFailedAuthenticationLogs() {
    return httpClient.get("api/get-failed-authentication-logs", {});
  },
  deleteFailedAuthenticationLog(failedAuthenticationLogID) {
    return httpClient.post("api/delete-failed-authentication-log", { FailedAuthenticationLogID: failedAuthenticationLogID });
  }
};

export default FailedAuthenticationLogsService;
