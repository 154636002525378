<template>
  <mex-dialog
    :showDialog="showSetNewMacAddressDialog"
    dialogTitle="Set new MAC address"
    width="50%"
  >
    <template v-slot:dialog-content>
      <v-overlay v-if="setNewMacAddress">
        <mex-sperm-spinner spinnerText="Setting new MAC address" />
      </v-overlay>
      <v-row justify="center">
        <v-col cols="auto">
          <mex-p fontSize="h6" alignment="center" content="You are about to change the MAC address of an already registered clinic. Please check
            again that this is really the current and correct MAC address of the clinic. If the MAC
            address stored and provided for an incoming request do not match, the request will be
            rejected." />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="macAddress"
            hide-details
            label="MAC Address"
            outlined
          />
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="10">
          <v-text-field
            v-model="clinicUUID"
            hide-details
            label="Clinic UUID"
            outlined
            readonly
          />
        </v-col>
      </v-row>
    </template>
    <template slot="dialog-actions">
      <v-row justify="center">
        <v-col cols="auto">
          <mex-btn content="Set MAC address" icon="mdi-plus" @click="setMacAddress" />
        </v-col>
        <v-col cols="auto">
          <mex-btn
            content="Discard"
            icon="mdi-delete"
            @click="discradSetNewMacAddressDialog"
          />
        </v-col>
      </v-row>
    </template>
  </mex-dialog>
</template>

<script>
import MexSpermSpinner from "../MedITEX_Vue_Components/MexComponents/MexSpermSpinner.vue";
import ClinicsService from "../../services/clinics.service";

export default {
  name: "SetNewMacAddressDialog",
  components: { MexSpermSpinner },
  props: {
    showSetNewMacAddressDialog: {
      type: Boolean,
      default: false
    },
    macAddressPreSelection: {
      type: String,
      default: ""
    },
    clinicUUIDPreSelection: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      // data
      macAddress: "",
      clinicUUID: "",
      // visualization
      setNewMacAddress: false
    };
  },
  watch: {
    macAddressPreSelection(newValue) {
      if (newValue !== "") {
        this.macAddress = newValue;
      }
    },
    clinicUUIDPreSelection(newValue) {
      if (newValue !== "") {
        this.clinicUUID = newValue;
      }
    }
  },
  mounted() {
    if (this.macAddressPreSelection) {
      this.macAddress = this.macAddressPreSelection;
    }
    if (this.clinicUUIDPreSelection) {
      this.clinicUUID = this.clinicUUIDPreSelection;
    }
  },
  methods: {
    discradSetNewMacAddressDialog() {
      this.macAddressPreSelection = "";
      this.clinicUUIDPreSelection = "";
      this.macAddress = "";
      this.clinicUUID = "";
      this.$emit("reject");
    },
    setMacAddress() {
      this.setNewMacAddress = true;
      ClinicsService.setNewMacAddress(this.clinicUUID, this.macAddress)
        .then(() => {
          this.$emit("confirm");
          this.$toast.success(this.macAddress + "  successfully set as new MAC address for " + this.clinicUUID);
          this.setNewMacAddress = false;
          this.macAddress = "";
          this.clinicUUID = "";
          this.macAddressPreSelection = "";
          this.clinicUUIDPreSelection = "";
        })
        .catch(err => {
          this.setNewMacAddress = false;
          this.$toast.error("Error while setting \"" + this.macAddress + "\" as new MAC address for " + this.clinicUUID + ": " + err.response.data);
        });
    }
  }
};
</script>

<style></style>
