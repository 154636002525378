var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.hasPermission)?_c('v-container',{attrs:{"fluid":""}},[(_vm.failedAuthenticationLogsLoading)?_c('mex-sperm-spinner',{attrs:{"spinnerText":"Loading FailedAuthenticationLogs"}}):[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('mex-heading',{attrs:{"content":"Failed Authentication Logs"}})],1)],1),_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-tabs',{staticClass:"mt-5",attrs:{"background-color":"foreground","color":"primaryAccent","grow":""},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"align-self":"center","cols":"auto"}},[_vm._v("Clinic")]),_c('v-col',{attrs:{"align-self":"center","cols":"auto"}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('v-col',{attrs:{"align-self":"center","cols":"auto"}},[_vm._v("Logs")])],1)],1),_c('v-tab',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"align-self":"center","cols":"auto"}},[_vm._v("Error")]),_c('v-col',{attrs:{"align-self":"center","cols":"auto"}},[_c('v-icon',[_vm._v("mdi-arrow-right")])],1),_c('v-col',{attrs:{"align-self":"center","cols":"auto"}},[_vm._v("Logs")])],1)],1)],1),_c('v-tabs-items',{staticStyle:{"background-color":"var(--v-foreground-base)"},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{staticClass:"pa-5"},[(_vm.clinicsToLogsData.length)?_c('mex-data-table',{attrs:{"data":_vm.clinicsToLogsData,"headers":_vm.clinicsToLogsHeaders,"items-per-page":_vm.getTablePagination.defaultRowsPerPage,"footer-props":{
                  'items-per-page-options': _vm.getTablePagination.rowsPerPage,
                },"table-class":"foreground","dense":"","show-expand":"","single-expand":"","item-key":"clinicUUID"},scopedSlots:_vm._u([{key:"item.clinicName",fn:function(ref){
                var item = ref.item;
return [(!!item.clinicId)?_c('v-btn',{staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToClinicView(item.clinicId)}}},[_vm._v(_vm._s(item.clinicName))]):_c('span',[_vm._v(_vm._s(item.clinicName || "-"))])]}},{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"extendedClinicRow",attrs:{"colspan":headers.length}},[_c('mex-data-table',{attrs:{"data":item.failedAuthenticationLogs,"headers":_vm.expandedClinicHeaders,"table-class":"foreground","dense":"","sort-by":['lastContact'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.lastContact",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dateFormatter.convertJsonDate(item.lastContact).full)+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-triangle-small-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.cmItems),function(cmItem,index){return _c('v-list-item',{key:item.FailedAuthenticationLogID + index,attrs:{"dense":"","disabled":!_vm.checkAction(item, cmItem)},on:{"click":function($event){return cmItem.function(item)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(cmItem.icon))])],1),_c('v-list-item-title',{staticClass:"ma-1",domProps:{"textContent":_vm._s(cmItem.title)}})],1)}),1)],1)]}}],null,true)})],1)]}}],null,false,529383397)}):_vm._e()],1),_c('v-tab-item',{staticClass:"pa-5"},[(_vm.errorToLogsData.length)?_c('mex-data-table',{attrs:{"data":_vm.errorToLogsData,"headers":_vm.errorToLogsHeaders,"items-per-page":_vm.getTablePagination.defaultRowsPerPage,"footer-props":{
                  'items-per-page-options': _vm.getTablePagination.rowsPerPage,
                },"table-class":"foreground","dense":"","show-expand":"","single-expand":"","item-key":"errorMessage"},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
                var headers = ref.headers;
                var item = ref.item;
return [_c('td',{staticClass:"extendedClinicRow",attrs:{"colspan":headers.length}},[_c('mex-data-table',{attrs:{"data":item.failedAuthenticationLogs,"headers":_vm.getExpandedErrorHeaders(item),"table-class":"foreground","dense":"","search":"Clinic UUID","sort-by":['lastContact'],"sort-desc":[true]},scopedSlots:_vm._u([{key:"item.expiredSince",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(item.expiredSince || "-")+" ")]}},{key:"item.clinicName",fn:function(ref){
                var item = ref.item;
return [(!!item.clinicId)?_c('v-btn',{staticClass:"pa-1",staticStyle:{"font-size":"smaller","height":"fit-content"},attrs:{"color":"foreground","small":""},on:{"click":function($event){return _vm.goToClinicView(item.clinicId)}}},[_vm._v(_vm._s(item.clinicName))]):_c('span',[_vm._v(_vm._s(item.clinicName || "-"))])]}},{key:"item.lastContact",fn:function(ref){
                var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$dateFormatter.convertJsonDate(item.lastContact).full)+" ")]}},{key:"item.actions",fn:function(ref){
                var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var attrs = ref.attrs;
                var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"white--text",attrs:{"color":"primary","x-small":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-triangle-small-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"dense":""}},_vm._l((_vm.cmItems),function(cmItem,index){return _c('v-list-item',{key:item.FailedAuthenticationLogID + index,attrs:{"dense":"","disabled":!_vm.checkAction(item, cmItem)},on:{"click":function($event){return cmItem.function(item)}}},[_c('v-list-item-icon',{staticClass:"mr-1"},[_c('v-icon',{attrs:{"small":""}},[_vm._v(_vm._s(cmItem.icon))])],1),_c('v-list-item-title',{staticClass:"ma-1",domProps:{"textContent":_vm._s(cmItem.title)}})],1)}),1)],1)]}}],null,true)})],1)]}}],null,false,3313224129)}):_vm._e()],1)],1)],1)],1),_c('add-mac-address-to-blocklist-dialog',{attrs:{"showAddMacAddressToBlocklistDialog":_vm.showAddMacAddressToBlocklistDialog,"macAddressPreSelection":_vm.macAddressPreSelection},on:{"reject":function($event){_vm.showAddMacAddressToBlocklistDialog = false},"confirm":function($event){_vm.showAddMacAddressToBlocklistDialog = false}}}),_c('add-clinic-uuid-to-blocklist-dialog',{attrs:{"showAddClinicUUIDToBlocklistDialog":_vm.showAddClinicUUIDToBlocklistDialog,"clinicUUIDPreSelection":_vm.clinicUUIDPreSelection},on:{"reject":function($event){_vm.showAddClinicUUIDToBlocklistDialog = false},"confirm":function($event){_vm.showAddClinicUUIDToBlocklistDialog = false}}}),_c('set-new-mac-address-dialog',{attrs:{"showSetNewMacAddressDialog":_vm.showSetNewMacAddressDialog,"clinicUUIDPreSelection":_vm.clinicUUIDPreSelection,"macAddressPreSelection":_vm.macAddressPreSelection},on:{"reject":function($event){_vm.showSetNewMacAddressDialog = false},"confirm":_vm.setNewMACAddress}})]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }